/*--------------------------------------------------------------
#0.3	header
--------------------------------------------------------------*/
.site-header {

	.navigation {
		background-color: $white;
		margin-bottom: 0;
		border: 0;
		@include rounded-border(0);

		> .container {
			position: relative;
		}
	}

	.navbar-header h1 {
		padding: 40px 10px;
		display: inline-block;
		font-size: 1.8em;
		font-weight: 600;
		color: $heading-color;
		position: relative;
		margin: 0;
		float: left;

		@include media-query(991px) {
			padding: 21px 10px;
		}		
	}

	#navbar {
		@include transition-time(0.5s);

		> ul li a:hover,
		> ul li a:focus,
		> ul li.current-menu-item a {
			text-decoration: none;
			color: $theme-primary-color !important;
		}

		// style for widther screen
		@include widther(992px) {
			li {
				position: relative;
			}

			> ul > li > a {
				font-size: 14px;
				font-size: calc-rem-value(14);
				font-weight: 500;
				text-transform: uppercase;
			}

			> ul .sub-menu {
				background-color: $white;
				width: 280px;
				text-align: left;
				padding: 25px 0;
				position: absolute;
				left: 0;
				top: 100%;
				z-index: 10;
				visibility: hidden;
				opacity: 0;
				@include transition-time(0.3s);
				box-shadow: 0 8px 15px rgba(0,0,0,.1);
				text-transform: capitalize;
			}

			> ul > li > ul.sub-menu {

				&:before {
					content: "";
				    width: 0;
				    height: 0;
				    border-style: solid;
				    border-width: 0 9px 10px 9px;
				    border-color: transparent transparent $white transparent;
				    margin: 0 0 0 20px;
				    padding-top: 1px;
				    position: absolute;
				    top: -11px;
				}
			}

			> ul > li .sub-menu li:last-child {
				border-bottom: 0;
			}

			> ul > li .sub-menu a {
				display: block;
				padding: 10px 30px;
				color: $text-color;
				font-size: 13px;
				font-size: calc-rem-value(13);
				font-weight: 600;
				text-transform: uppercase;

				&:hover {
					padding-left: 35px;
				}
			}

			> ul > li > .sub-menu .sub-menu {
				left: 95%;
				top: 0;
			}

			> ul > li > .sub-menu > .menu-item-has-children > a {
				position: relative;

				&:before {
					font-family: "themify";
					content: "\e649";
					font-size: 11px;
					font-size: calc-rem-value(11);
					position: absolute;
					right: 25px;
					top: 50%;
					@include translatingY();
				}
			}

			/*** hover effect ***/
			> ul > li:hover > .sub-menu {
				top: 100%;
				visibility: visible;
				opacity: 1;
			}

			.sub-menu > li:hover > .sub-menu {
				left: 100%;
				visibility: visible;
				opacity: 1;
			}
		}

		@include media-query(991px) {

			> ul > li a {
				display: block;
				font-size: 14px;
				font-size: calc-rem-value(14);
			}

			> ul > li .sub-menu li {
				border-bottom: 1px solid lighten($black, 90%);
			}

			> ul .sub-menu > li:last-child {
				border-bottom: 0;
			}

			> ul > li > .sub-menu a {
				padding: 8px 15px 8px 45px;
			}

			> ul > li > .sub-menu .sub-menu a {
				padding: 8px 15px 8px 65px;
			}

			> ul .menu-item-has-children > a {
				position: relative;

				&:before {
					font-family: "themify";
					content: "\e61a";
					font-size: 11px;
					font-size: calc-rem-value(11);
					position: absolute;
					right: 15px;
					top: 50%;
					@include translatingY();
				}
			}
		}


		/*** mega-menu style ***/
		@include widther(992px) {
			.has-mega-menu {
				position: static;
			}

			.mega-menu,
			.half-mega-menu {
				background-color: $white;
				padding: 20px;
				border-top: 2px solid $theme-primary-color;
				position: absolute;
			    right: 0;
			    top: 100%;
			    z-index: 10;
			    visibility: hidden;
			    opacity: 0;
			    @include transition-time(0.3s);
			}

			.mega-menu {
				width: 1140px;
				right: 15px;
			}

			.half-mega-menu {
				width: 585px;
			}

			.mega-menu-box-title {
				font-size: 14px;
				font-size: calc-rem-value(14);
				text-transform: uppercase;
				font-weight: bold;
				display: block;
				padding-bottom: 7px;
				margin-bottom: 7px;
				border-bottom: 1px solid lighten($black, 90%);
			}

			.mega-menu-list-holder li a {
				font-size: 14px;
				font-size: calc-rem-value(14);
				display: block;
				padding: 7px 8px;
				margin-left: -8px;
			}

			/*** hover effect ***/
			.has-mega-menu:hover > ul {
				top: 100%;
				visibility: visible;
				opacity: 1;
			}
		}

		@include media-query(1199px) {
			> ul .mega-menu {
				width: 950px;
				right: 15px;
			}

			> ul .half-mega-menu {
				width: 485px;
			}
		}

		@include media-query(991px) {
			> ul .mega-menu,
			> ul .half-mega-menu {
				width: auto;
			}

			> ul .mega-menu .row,
			> ul .half-mega-menu .row {
				margin: 0;
			}

			.mega-menu-content > .row > .col {
				margin-bottom: 25px;
			}
		}

		@include media-query(991px) {
			.mega-menu .mega-menu-list-holder a {
				padding: 5px 15px 5px 40px;
			}

			.mega-menu .mega-menu-box-title {
				font-size: 14px;
				font-size: calc-rem-value(14);
				text-transform: uppercase;
				display: block;
				border-bottom: 1px dotted lighten($black, 70%);
				padding: 0 0 4px 5px;
				margin: 0 25px 8px 25px;
			}
		}
	}

	/* navigation open and close btn hide for width screen */
	@include widther(992px) {
		.navbar-header .open-btn {
			display: none;
		}

		#navbar .close-navbar {
			display: none;
		}
	}


	/* style for navigation less than 992px */
	@include media-query (991px) {
		.container {
			width: 100%;
		}

		.navbar-header button {
			background-color: $theme-primary-color;
			width: 40px;
			height: 35px;
			border: 0;
			padding: 5px 10px;
			outline: 0;
			position: absolute;
			right: 15px;
			top: 6px;
			z-index: 20;
			top: 50%;
			@include translatingY();
			border-radius: 3px;



			 span {
				background-color: $white;
				display: block;
				height: 2px;
				margin-bottom: 5px;

				&:last-child {
					margin: 0;
				}
			 }
		}

		#navbar {
			background: $white;
			display: block !important;
			width: 280px;
			height: 100% !important;
			margin: 0;
			padding: 0;
			border-left: 1px solid lighten($black, 80%);
			border-right: 1px solid lighten($black, 80%);
			position: fixed;
			right: -330px;
			top: 0;
			z-index: 100;

			ul a {
				color: $black;
			}

			ul a:hover,
			ul li.current a {
				color: $theme-primary-color;
			}

			.navbar-nav {
				height: 100%;
				overflow: auto;
			}

			.close-navbar {
				background-color: $theme-primary-color;
				width: 40px;
				height: 40px;
				color: $white;
				border: 0;
				outline: none;
				position: absolute;
				left: -41px;
				top: 90px;
				z-index: 20;

				.ti-close {
					position: relative;
					top: 1px;
				}
			}

			> ul > li {
				border-bottom: 1px solid lighten($black, 95%);
			}

			> ul > li > a {
				padding: 10px 15px 10px 35px;
			}
		}

		/* class for show hide navigation */
		.slideInn {
			right: 0 !important;
		}
	}

	@include media-query(767px) {
		.navbar-header .navbar-brand {
			font-size: 24px;
		}

		#navbar .navbar-nav {
			margin: 0;
		}
	}


	/*navbar collaps less then 992px*/
	@include media-query (991px) {
	    .navbar-collapse.collapse {
	        display: none;
	    }

	    .navbar-collapse.collapse.in {
	        display: block;
	    }

	    .navbar-header .collapse,
	    .navbar-toggle {
	        display:block;
	    }

	    .navbar-header {
	        float:none;
	    }

	    .navbar-right {
	        float: none;
	    }

	    .navbar-nav {
	    	float: none;
	    }

	    .navbar-nav > li {
	        float: none;
	    }
	}
}

@include media-query(991px) {
	.page-wrapper {
		@include transition-time(0.3s);
	}
	.body-overlay:before {
		content: "";
		width: 100%;
		height: 100%;
		background: transparentize($black, 0.1);
		position: absolute;
		left: 0;
		top: 0;
		z-index: 10;
		@include transition-time(0.3s);
	}
}

/*---------------------------------------
	#header-style-1
----------------------------------------*/
.header-style-1 {
	@include widther(992px) {
		width: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 10;
	}

	.navbar-brand img {
		max-height: 90px;

		@include media-query(991px) {
			max-height: 70px;
		}
	}

	.navigation {
		background: transparent;
	}

	@include widther(992px) {
		.navigation {
			padding: 0 60px 0 45px;
			border-bottom: 1px solid transparentize($white, 0.8);
		}

		.navbar-brand {
			padding-top: 0;
			padding-bottom: 0;
			height: auto;
			margin-top: 12px;
		}

		#navbar > ul > li > a {
			color: $white;
			padding: 46px 15px;
			position: relative;

			&:before {
				content: "";
				background: transparent;
				width: 80%;
				height: 35px;
				border: 2px solid $theme-primary-color;
				border-radius: 50px;
				position: absolute;
				left: 10%;
				bottom: calc(50% - 17px);
				opacity: 0;
				@include transition-time(0.3s);
				z-index: -1;
			}
		}

		#navbar > ul > li.current-menu-parent > a {
			font-weight: 600;
			color: $theme-primary-color;
		}

		#navbar > ul > li.current-menu-parent > a:before,
		#navbar > ul > li.current-menu-item > a:before {
			opacity: 1;
		}

		#navbar ul.sub-menu li.current-menu-parent > a {
			color: $theme-primary-color;
		}
	}

	@include widther(1200px) {
		#navbar > ul > li > a {
			padding: 46px 20px;
		}
	}

	@include widther(1365px) {
		#navbar > ul > li > a {
			padding: 46px 25px;
		}
	}

	@include media-query(991px) {
		.navigation {
			background: #111;
			padding: 25px 0;
			border-bottom: 1px solid transparentize($white, 0.8);
		}

		.navbar-brand {
			height: auto;
			padding: 0 15px;
		}
	}

	@include media-query(1199px) {
		.donate-btn {
			display: none;
		}
	}

	.navbar-search {
		border-left: 1px solid transparentize($dark-gray, 0.8);
	}

	/*** cart-search-contact ***/
	.cart-search-contact {
		position: absolute !important;
		right: 60px;
		top: 0;
		height: 113px;
		padding-top: 0px;
		padding-left: 15px;
		border-left: 1px solid rgba(255, 255, 255, 0.2);

		&-controls {
			padding-top: 29px;
			right: 0px;
			border: 0;
			@include media-query(991px) {
				padding-top: 0;
			}			
		}

		@include media-query(991px) {
			position: relative !important;
			right: 0;
			padding-left: 0;
			height: auto;
		}

		button {
			background: transparent;
			padding: 0;
			border: 0;
			outline: 0;
			position: relative;
		}

		> div {
			float: left;
			position: relative;
		}

		button > i {
			font-size: 20px;
			color: $white;
		}

		.header-search-form {
			position: absolute;
		    width: 250px;
		    right: 0;
		    top: 84px;
		    opacity: 0;
		    visibility: hidden;
		    @include transition-time(0.5s);
			box-shadow: -2px 18px 40px -9px rgba(153,153,153,1);
			z-index: 10;

		    @include media-query(991px) {
				top: 70px;
			}

			@include media-query(767px) {
				top: 70px;
				right: 15px;
			}

			button i {
				color: $heading-color;
			}
		}

		.header-search-form-wrapper .fi:before {
			font-size: 22px;
			font-size: calc-rem-value(22);
		}

		.header-search-form-wrapper,
		.mini-cart {
			padding-top: 8px;
		}

		form div {
			position: relative;

			button {
				position: absolute;
				right: 15px;
				top: 56%;
				@include translatingY();
			}
		}

		input {
			width: 100%;
			height: 50px;
			padding: 6px 20px;
			border: 0;
			border-radius: 0;
			box-shadow: none;
		}

		.mini-cart {
			margin-right: 25px;

			@include media-query(991px) {
				display: none;
			}
		}

		.mini-cart-btn {
			display: none;
			float: none;
			margin-bottom: 20px;
			@include media-query(991px) {
				display: block;
			}			
		}

		.mini-cart .fi:before {
			font-size: 25px;
			font-size: calc-rem-value(25);
		}

	    .mini-cart .cart-count {
	    	background: $theme-primary-color;
			width: 22px;
			height: 22px;
			line-height: 22px;
			font-size: 10px;
			font-size: calc-rem-value(10);
			color: white;
			position: absolute;
			top: 0;
			right: -5px;
			border-radius: 50%;
	    }

		.mini-cart-content {
			background: $white;
			width: 300px;
			z-index: 10;
			position: absolute;
			right: 0;
			top: 84px;
		    opacity: 0;
		    visibility: hidden;
		    @include transition-time(0.5s);
			box-shadow: -2px 18px 40px -9px rgba(153,153,153,1);

		    @include media-query(991px) {
				top: 70px;
			}

			@include media-query(767px) {
				top: 70px;
			}

		    @include media-query(450px) {
				right: auto;
				left: -185px;
			}

			p {
				font-size: 15px;
				font-size: calc-rem-value(15);
				font-weight: 500;
				color: $heading-color;
				margin: 0;
				text-transform: uppercase;
			}

			.mini-cart-items {
				padding: 25px 20px;
			}

			.mini-cart-item {
				padding-top: 15px;
				margin-top: 15px;
				border-top: 1px solid #efefef;
			}

			.mini-cart-item:first-child {
				padding-top: 0;
				margin-top: 0;
				border-top: 0;
			}


			.mini-cart-item-image {
				width: 50px;
				height: 50px;
				border: 2px solid #f3f3f3;
				float: left;
				margin-right: 15px;
			}

			.mini-cart-item-image a,
			.mini-cart-item-image img {
				display: block;
				width: 46px;
				height: 46px;
			}

			.mini-cart-item-des {
				position: relative;
				overflow: hidden;
			}

			.mini-cart-item-des a {
				font-size: 14px;
				font-size: calc-rem-value(14);
				font-weight: 600;
				text-align: left;
				color: $heading-color;
			}

			.mini-cart-item-des a:hover {
				color: $theme-primary-color;
			}


			.mini-cart-item-des .mini-cart-item-price {
				font-size: 13px;
				font-size: calc-rem-value(13);
				color: #888;
				display: block;
				margin-top: 3px;
			}

			.mini-cart-item-des .mini-cart-item-quantity {
				font-size: 12px;
				font-size: calc-rem-value(12);
				color: #444;
				display: block;
				position: absolute;
				right: 0;
				top: 2px;
			}

			.mini-cart-action {
				padding: 20px 0 30px;
				border-top: 1px solid #efefef;
				text-align: center;

				.theme-btn-s2 {
					float: right;
				}
			}

			.mini-cart-action .mini-checkout-price {
				font-size: 18px;
				font-size: calc-rem-value(18);
				font-weight: 600;
				color: $heading-color;
			    display: inline-block;
			}
		}

		.mini-cart-content-toggle,
		.header-search-content-toggle {
			opacity: 1;
			visibility: visible;
			right: 0;
		}

		.cart-toggle-btn,
		.search-toggle-btn {
			width: 40px;
			height: 40px;
			line-height: 40px;
			text-align: center;
		}
	}	
}


/*---------------------------------------
	#header-style-2
----------------------------------------*/
.header-style-2 {
	@extend .header-style-1;
	
	@include widther(992px) {
		position: relative;

		#navbar > ul > li > a {
			font-weight: 600;
			color: $heading-color;
			position: relative;

			&:before {
				content: "";
				background-color: $button-link-bg-color;
				border: 0;
				width: 80%;
				height: 28%;
				position: absolute;
				left: 10%;
				top: 36%;
				border-radius: 50px;
				z-index: -1;
				opacity: 0;
				@include transition-time(0.3s);
			}

			&:hover:before {
				opacity: 1;
			}
		}

		#navbar > ul > li.current-menu-parent > a:before, 
		#navbar > ul > li.current-menu-item > a:before {
			opacity: 1;
		} 

		#navbar > ul .sub-menu li.current-menu-item a {
			color: $theme-primary-color;
		}


		.cart-search-contact button > i {
			color: $text-color;
		}

		.cart-search-contact {
			border-color: #e2e2e2;
		}

		#navbar > ul .sub-menu {
			background-color: $text-color;
		}

		#navbar > ul > li:hover > .sub-menu {
			top: 101%;
		}

		#navbar > ul > li .sub-menu a {
			color: $white;
		}

		#navbar > ul > li > ul.sub-menu:before {
		    border-color: transparent transparent $text-color transparent;
		}
	}

	@include media-query(991px) {
		.navigation {
			background-color: $white;
		}

		.cart-search-contact button > i {
			color: $text-color;
		}

		.cart-search-contact {
			border-color: #e2e2e2;
		}
	}
}




/*---------------------------------------
	#header-style-3
----------------------------------------*/
.header-style-3 {
	@extend .header-style-1;

	.topbar {
		background-color: $white;
		padding: 11px 0;

		@include media-query(991px) {
			text-align: center;
			padding: 15px 0 25px;
		}
	}

	.social {

		span {
		    padding-right: 20px;
		    position: relative;
		    top: -6px;
		    display: inline-block;

		    @include media-query(1199px) {
		    	display: none;
		    }
		}

		ul {
			display: inline-block;

			@include media-query(1199px) {
		    	margin-left: 75px;
		    }

		    @include media-query(991px) {
				margin-left: 0;
			}
		}

		a {
			font-size: 13px;
			font-size: calc-rem-value(13);
			color: $heading-color;
			display: inline-block;
		}

		ul li {
			float: left;
		}

		ul > li + li {
			margin-left: 15px;
		}

		a:hover {
			color: $theme-primary-color;
		}
	}

	.link-donate {
		ul {
			display: inline-block;

			@include media-query(991px) {
				padding: 15px 0;
			}
		}

		ul li {
			float: left;

			@include media-query(991px) {
				float: none;
			}

		}

		ul > li + li {
			position: relative;
			margin-left: 15px;
			padding-left: 15px;

			@include media-query(991px) {
				margin: 3px 0 0;
				padding: 0;
			}

			&:before {
				content: "";
				background-color: $heading-color;
				width: 2px;
				height: 13px;
				position: absolute;
				left: 0;
				top: 5px;

				@include media-query(991px) {
					display: none;
				}
			}
		}

		ul a {
			font-size: 14px;
			font-size: calc-rem-value(14);
			color: $heading-color;
			font-weight: 600;
		}

		ul a:hover {
			color: $theme-primary-color;
		}
	}

	.donate-btn-wrap {
		float: right;

		@include media-query(991px) {
			float: none;
		}
	}

	.donate-btn-2 {
		font-size: 14px;
		font-size: calc-rem-value(14);
		font-weight: 600;
		color: $theme-primary-color;
		border-bottom: 1px solid $theme-primary-color;
		text-transform: uppercase;
	}

	@include widther(992px) {
		.navbar-brand {
			position: absolute;
		    top: -86px;
		    left: 0;
		}

		#navbar {
			margin-left: 150px;
		}

		#navbar > ul > li > a {
		    padding: 35px 20px;
		}

		.cart-search-contact {
			height: 91px;
			right: 0;
			padding-top: 20px;
		}

		.cart-search-contact .mini-cart {
		    margin-right: 0;
		}

		.cart-search-contact .mini-cart-content,
		.cart-search-contact .header-search-form {
			top: 71px;
		}
	}

	@include media-query(991px) {
		.navbar-brand img {
			max-width: 100px;
		}

		.navigation {
			padding: 0;
		}
	}

}