/*--------------------------------------------------------------
#0.5	footer
--------------------------------------------------------------*/
.site-footer {
	@include background-style("../../images/partners/partners-bg.jpg", center center, cover, no-repeat, local);
	position: relative;
	font-size: 15px;
	overflow: hidden;

	&:before {
		content: "";
		background-color: transparentize(#000, 0.08);
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	ul {
		list-style: none;
	}

	p,
	li {
		color: $white;
	}


	.upper-footer {
		padding: 90px 0;

		@include media-query(991px) {
			padding: 90px 0 20px;
		}

		@include media-query(767px) {
			padding: 80px 0 10px;
		}

		.col {
			@include media-query(991px) {
				min-height: 235px;
				margin-bottom: 70px;
			}

			@include media-query(767px) {
				min-height: auto;
				margin-bottom: 60px;
			}
		}
	}

	.widget-title {
		margin-bottom: 30px;

		@include media-query(767px) {
			margin-bottom: 20px;
		}
	}

	.widget-title h3 {
		font-size: 20px;
		font-size: calc-rem-value(20);
		color: $white;
		margin: 0;
		padding-bottom: 0.4em;
		text-transform: capitalize;
		position: relative;

		@include media-query(991px) {
			padding-bottom: 0;
		}
	}

	/*** about-widget ***/
	.about-widget {
		.logo {
			max-width: 180px;
		}

		p {
			margin-bottom: 2em;
			line-height: 1.9em;
		}

		p:last-child {
			margin-bottom: 0;
		}

		.social-icons {
			overflow: hidden;

			@include media-query(991px) {
				float: none;
				display: block;
				text-align: center;
			}
		}

		.social-icons ul {
			overflow: hidden;
			list-style: none;

			@include media-query(991px) {
				display: inline-block;
			}

			li {
				float: left;
			}

			> li + li {
				margin-left: 15px;
			}

			a {
				background-color: #303030;
				width: 30px;
				height: 30px;
				line-height: 36px;
				text-align: center;
				font-size: 14px;
				font-size: calc-rem-value(14);
				color: $white;
				display: inline-block;
				border-radius: 5px;
			}

			a:hover {
				color: $theme-primary-color;
			}
		}

	}

	/*** link-widget ***/
	.link-widget {
		overflow: hidden;

		@include widther(1200px) {
			padding-left: 20px;
		}

		@include media-query(1199px) {
			padding-left: 20px;
		}

		@include media-query(991px) {
			padding-left: 0;
		}

		@include media-query( 767px ) {
	       max-width: 350px;
	    }

		ul {
			width: 50%;
			float: left;
		}

		ul li {
			position: relative;
		}

		ul a {
			color: $white;
			font-weight: 500;
		}

		ul a:hover,
		ul li:hover:before {
			text-decoration: underline;
		}

		ul > li + li {
			margin-top: 15px;
		}
	}

	/*** contact-widget ***/
	.contact-widget {
		@include widther(1200px) {
			padding-left: 25px;
		}

		ul {
			margin-top: 25px;
		}

		ul li {
			position: relative;
		}

		ul > li + li {
			margin-top: 15px;
		}

		li span {
			color: $white;
			font-weight: 500;
		}
	}

	/*** newsletter-widget ***/
	.newsletter-widget {
		form {
			margin-top: 25px;
			position: relative;

			input {
				background-color: #303030;
				height: 60px;
				color: $white;
				padding: 6px 20px;
				border-radius: 5px;
				border: 0;
				box-shadow: none;
			}

			.submit {
				position: absolute;
				right: 8px;
				top: 7px;

				button {
					background: $theme-primary-color;
					width: 48px;
					height: 45px;
					border: 0;
					outline: 0;
					position: relative;
					border-radius: 5px;
					font-size: 20px;
					color: $white;
				}
			}
		}
	}


	/*** lower-footer ***/
	.lower-footer {
		text-align: center;
		position: relative;

		.row {
			padding: 20px 0;
			position: relative;

			.separator {
				background: transparentize($white, 0.9);
				width: calc(100% - 30px);
				height: 1px;
				position: absolute;
				left: 15px;
				top: 0;
			}
		}

		.copyright {
			display: inline-block;
			float: left;
			font-size: 14px;
			font-size: calc-rem-value(14);
			margin: 0;

			@include media-query(991px) {
				float: none;
				display: block;
				margin: 0 0 20px;
			}

			a {
				color: $white;
				text-decoration: underline;
			}
		}

		.extra-link {
			display: inline-block;
			float: right;
			overflow: hidden;

			@include media-query(991px) {
				float: none;
				display: block;
				text-align: center;
			}
		}

		.extra-link ul {
			overflow: hidden;
			list-style: none;

			@include media-query(991px) {
				display: inline-block;
			}

			li {
				float: left;
			}

			> li + li {
				margin-left: 35px;
				position: relative;

				@include media-query(767px) {
					margin-left: 15px;
				}

				&:before {
					content: "";
					background: $heading-color;
					width: 15px;
					height: 1px;
					position: absolute;
					left: -25px;
					top: 11px;

					@include media-query(767px) {
						display: none;
					}
				}
			}

			a {
				font-size: 14px;
				font-size: calc-rem-value(14);
				color: $white;
			}

			a:hover {
				text-decoration: underline;
			}
		}

	}
}