.preloader {
	background-color: $text-color;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 1000;
	top: 0;
	opacity: 0.7;

	.preloader-inner {
		width: 80px;
		height: 80px;
		position: absolute;
		left: calc(50% - 40px);
		top: calc(50% - 40px);
	}

	.double-bounce1, 
	.double-bounce2 {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-color: $theme-primary-color;
		opacity: 0.8;
		position: absolute;
		top: 0;
		left: 0;

		-webkit-animation: sk-bounce 2.0s infinite ease-in-out;
		animation: sk-bounce 2.0s infinite ease-in-out;
	}

	.double-bounce2 {
		-webkit-animation-delay: -1.0s;
		animation-delay: -1.0s;
	}

	@-webkit-keyframes sk-bounce {
		0%, 100% { -webkit-transform: scale(0.0) }
		50% { -webkit-transform: scale(1.0) }
	}

	@keyframes sk-bounce {
		0%, 100% { 
		transform: scale(0.0);
		-webkit-transform: scale(0.0);
		} 50% { 
			transform: scale(1.0);
			-webkit-transform: scale(1.0);
		}
	}
}