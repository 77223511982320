	/*
  	Flaticon icon font: Flaticon
  	Creation date: 08/08/2020 01:45
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff2") format("woff2"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-play-button:before { content: "\f100"; }
.flaticon-back:before { content: "\f101"; }
.flaticon-next:before { content: "\f102"; }
.flaticon-right-quote:before { content: "\f103"; }
.flaticon-left-quote:before { content: "\f104"; }
.flaticon-quote:before { content: "\f105"; }
.flaticon-paper-plane:before { content: "\f106"; }
.flaticon-envelope:before { content: "\f107"; }
.flaticon-placeholder:before { content: "\f108"; }
.flaticon-diamond:before { content: "\f109"; }
.flaticon-diamond-1:before { content: "\f10a"; }
.flaticon-message:before { content: "\f10b"; }
.flaticon-mail:before { content: "\f10c"; }
.flaticon-guarantee:before { content: "\f10d"; }
.flaticon-coffee-cup:before { content: "\f10e"; }
.flaticon-search:before { content: "\f10f"; }
.flaticon-replay-arrow:before { content: "\f110"; }
.flaticon-house:before { content: "\f111"; }
.flaticon-call:before { content: "\f112"; }
.flaticon-email:before { content: "\f113"; }
.flaticon-alarm:before { content: "\f114"; }
.flaticon-view:before { content: "\f115"; }
.flaticon-speech-bubble:before { content: "\f116"; }
.flaticon-feedback:before { content: "\f117"; }
.flaticon-agent:before { content: "\f118"; }
.flaticon-coin:before { content: "\f119"; }
.flaticon-cost:before { content: "\f11a"; }
.flaticon-money-bag:before { content: "\f11b"; }
.flaticon-umbrella:before { content: "\f11c"; }
.flaticon-smile:before { content: "\f11d"; }
.flaticon-play:before { content: "\f11e"; }
.flaticon-left-arrow-angle-big-gross-symbol:before { content: "\f11f"; }
.flaticon-arrow-angle-pointing-to-right:before { content: "\f120"; }
.flaticon-tag:before { content: "\f121"; }
.flaticon-quote-1:before { content: "\f122"; }
.flaticon-supermarket:before { content: "\f123"; }
.flaticon-tag-1:before { content: "\f124"; }
.flaticon-pdf:before { content: "\f125"; }
.flaticon-user:before { content: "\f126"; }
.flaticon-calendar:before { content: "\f127"; }
.flaticon-like:before { content: "\f128"; }
.flaticon-heart:before { content: "\f129"; }
.flaticon-heart-1:before { content: "\f12a"; }
.flaticon-shopping-basket:before { content: "\f12b"; }
.flaticon-triangular-ruler:before { content: "\f12c"; }
.flaticon-pencil-case:before { content: "\f12d"; }
.flaticon-fruits:before { content: "\f12e"; }
.flaticon-charity:before { content: "\f12f"; }
.flaticon-donation:before { content: "\f130"; }
.flaticon-play-1:before { content: "\f131"; }
.flaticon-down-arrow:before { content: "\f132"; }
.flaticon-down-arrow-1:before { content: "\f133"; }
.flaticon-down-arrow-2:before { content: "\f134"; }
.flaticon-down:before { content: "\f135"; }
.flaticon-down-arrow-3:before { content: "\f136"; }